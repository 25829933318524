@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Armata&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lobster', cursive;
}

h1 {
  font-size: 35px;
}

h5 {
  font-size: 16px;
  font-weight: 700;
  font-family: Armata;
  color: #807777;
}

header {
  padding: 2em 0;
}

footer {
  color: black;
  text-align: center;
  font-size: 0.8em;
  background-color: #eae8e4;
  border-top: 5px solid #7850fa;
  margin: 2em 0 0 0;
  padding: 5px;
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 576px) {
  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.error {
  text-align: center;
}

table {
  margin-top: 2em;
}

.form-control {
  background-color: #eae8e4;
}

.main-container {
  margin-bottom: 45px;
}

.header-container {
  background-color: #eae8e4;
  border-bottom: 10px solid #7850fa;
  color: black;
  margin-bottom: 2em;
  padding-bottom: 0px;
}

.btn-primary {
  color: #fff;
  background-color: #7850fa;
  border-color: #7850fa;
  text-transform: uppercase;
}

.performance {
  color: #0dcfc7;
  font-style: italic;
}

.sales-summary {
  margin-top: 2em;
}

.sales-summary > div {
  display: flex;
}

.sales-summary span {
  flex: auto;
  width: 50%;
}

.logout {
  align-items: center;
  display: flex;
  float: right;
}

.logout span {
  flex: 1;
  padding: 0 0.9em 0 0;
  white-space: nowrap;
}

.username {
  font-weight: bold;
}

#robot-preview {
  width: 380px;
  margin: 0 auto;
  text-align: center;
}

#robot-preview-image {
  border: 3px solid #7850fa;
  background: #eee;
  padding: 15px 10px;
}

#robot-preview-image img {
  width: 100%;
  height: auto;
}

.modal {
  background-color: rgba(0, 0, 0, 80%);
}
.modal-header {
  border-bottom: 0;
}

.modal-content {
  background-color: #7850fa;
  border-color: #7850fa;
}

.modal-dialog {
  color: #fff;
  text-transform: uppercase;
}

.alert-buttons button {
  margin-right: 5px;
}

button#order {
  margin-left: 10px;
}

.attribution {
  font-size: 9px;
}

.radio label,
.radio {
  cursor: pointer;
}

.stacked {
  display: flex;
  flex-wrap: wrap;
}

.stacked .radio {
  flex: 1 0 33%;
  margin: 0 5px;
}

.text-muted {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6) !important;
}

.form-control label {
  font-size: 13px;
  margin-bottom: 0.3rem;
}

.nav-pills .nav-link {
  margin-top: 24px;
  background: #fff;
  color: #7850fa;
  margin-right: 12px;
  border-radius: 4px 4px 0 0;
}

.nav-pills .nav-link.active {
  background: #7850fa;
}
