html {
  position: relative;
  min-height: 100%;
}

html, body, div#main {
  margin: 0;
  padding: 0;
}

body {
  background-color: #2e282e;
  color: white;
  margin-bottom: 6em;
  font-family: 'Armata', sans-serif;
}